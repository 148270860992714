<template>
  <div id="payments">
    <tablebody></tablebody>
  </div>
</template>

<script>
import tablebody from '@/components/TableBody'

export default {
  name: 'payments',
  components: {tablebody}
  }
</script>
<style scoped>
div {
  max-width: 100%;
  background-color: #f2f2f2;
  font-size: 14px;
}
</style>
