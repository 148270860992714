<template>
  <div>
    <div class="page_container">
      <div class="row">
        <div class="invoice-container
                    col-sm-6 col-sm-offset-3
                    col-md-4 col-md-offset-4">
          <div class="invoice" v-bind:id="parameters.id">
            <div class="invoice-header text-center">
              <div class="partner_name">{{parameters.title}}</div>
              <div class="term_name">{{ parameters.term_name }}</div>
            </div>
            <div class="invoice-body" >
              <div class="clearfix">
                <div class="float-left">Чек</div>
                <div class="float-right">#{{ parameters.billnumber }}</div>
              </div>

              <div class="invoice-timestamp clearfix">
                <div class="float-left">Дата</div>
                <div class="float-right">{{parameters.serverdate}}</div>
              </div>
                <div >
                  <div class="transaction-parameters clearfix">
                    <div class="float-left"> Оплата за</div>
                    <div class="float-right serv_name"> {{ parameters.svr_name }} </div>
                  </div>

                  <div class="clearfix">
                    <div class="float-left">Реквизит</div>
                    <div class="float-right">{{parameters.requisite}}</div>
                  </div>
                </div>
                <div>
                  <div class="clearfix">
                    <div class="float-left">Принято</div>
                    <div class="float-right">{{ parameters.amount }} сом</div>
                  </div>

                  <div class="clearfix">
                    <div class="float-left">Комиссия</div>
                    <div class="float-right">{{ parameters.comission }} сом</div>
                  </div>

                  <div class="transaction-in-total clearfix ">
                    <div class="float-left">Итого к зачислению</div>
                    <div style="float:right; ">{{ (parameters.amount-parameters.comission).toFixed(2) }} сом</div>
                  </div>
                </div>

              <div class="contact-information text-left">
                <div>ИНН: {{parameters.inn}}</div>
                <div>Тел: {{parameters.contact}} </div>
              </div>
            </div>

            <div class="invoice-footer text-center">
              Спасибо, что выбрали нас!
            </div>

            <div class="umai-stamp"><img src="@/assets/images/umai-stamp.png" /></div>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden-print"
         ng-include="'components/layout/footer.html'">
     <b-button size="sm" @click="printJS({printable: parameters.id, type: 'html', documentTitle: 'Чек', targetStyles: ['*']})">
       Печать чека
     </b-button>

    </div>
  </div>
</template>
<script>
import printJS from 'print-js';
export default {
  props: {
    parameters: Object
  },
  methods: {
    printJS: printJS,
  }
}
</script>
<style>
  .page_container {
    width: 260px;
    margin: 30px 0;
    background-color: white;
  }
  .invoice {
    position: relative;
    width: 260px;
    color: black;
    border: 1px dashed black;
    padding: 10px;
  }
  .image {
    position	: relative;
    width		: 100%;
  }
  .invoice-header {
    padding-bottom: 5px;
    font-family: monospace;
    font-size: 10pt;
    font-weight: 700 !important;
    border-bottom: 1px dashed black;
  }
  .invoice-body {
    font-family: "Fira Mono", monospace;
    font-size: 9pt;
    font-weight: 200 !important;
    padding-top: 5px;

  }
  .partner_name {
    font-size: 12pt;
  }
  .term_name {
    font-size: 9pt;
  }
  .invoice-timestamp {
    padding-top: 5px;
  }
  .transaction-parameters {
    padding-top: 5px;

  }
  .serv_name {
    width: 170px;
    text-align: right;
  }
  .transaction-in-total {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 700 !important;
  }
  .contact-information {
  border-top: 1px dashed black;
  padding-top: 5px;
  padding-bottom: 5px;
  }
  .umai-stamp {
    position: absolute;
    bottom: -15px;
    right: -50px;
    opacity: .8;
  }
  .invoice-footer {
    font-family: sans-serif;
    font-size: 9pt;
    font-weight: 200 !important;
    border-top: 1px dashed black;
    padding-top: 10px;
  }
  @media print {
         @page {
           padding: 0;
           margin: 0;
        }
  }

</style>
