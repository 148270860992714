import { render, staticRenderFns } from "./TableBody.vue?vue&type=template&id=9f667384&scoped=true&"
import script from "./TableBody.vue?vue&type=script&lang=js&"
export * from "./TableBody.vue?vue&type=script&lang=js&"
import style0 from "./TableBody.vue?vue&type=style&index=0&id=9f667384&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f667384",
  null
  
)

export default component.exports