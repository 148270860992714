  <template>
  <div id="app" class="tablebody">
    <div class="info">
      <div class="paycount" id="paycount1">{{totalRows}}</div>
      <b-tooltip target="paycount1">Количество платежей</b-tooltip>
      <div class="paycount" id="paycount2">{{totalsum}}</div>
      <b-tooltip target="paycount2">Сумма</b-tooltip>
      <div class="paycount" id="paycount3">{{totalComission}}</div>
      <b-tooltip target="paycount3">Комиссия</b-tooltip>
      <b-btn class='paycount' size="sm" v-on:click="updatedata"> <b-icon-arrow-repeat> </b-icon-arrow-repeat> </b-btn>
    </div>
    <b-container fluid>
      <b-row>
        <b-col md="3">
          <b-input-group  style="margin-bottom: .25rem!important">
            <b-form-input v-model="requisite" placeholder="Реквизит" size="sm" style="background-color: #c6ced4"/>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!requisite" @click="requisite = ''" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
          <b-input-group  style="margin-bottom: .25rem!important">
            <b-form-input v-model="billnum" placeholder="Чек" size="sm" style="background-color: #c6ced4"/>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!billnum" @click="billnum = ''" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="3">
          <b-input-group style="margin-bottom: .25rem!important">
            <b-form-input v-model="id" placeholder="ID" size="sm" style="background-color: #c6ced4"/>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!id" @click="id = ''" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
          <b-input-group  style="margin-bottom: .25rem!important">
            <b-form-input v-model="guid" placeholder="GUID" size="sm" style="background-color: #c6ced4"/>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!guid" @click="guid = ''" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="3">
          <b-input-group  style="margin-bottom: .25rem!important">
            <b-form-select v-model="select1" :options="selectList1" size="sm" v-if='selectList[0].visible' style="background-color: #c6ced4" >
              <template slot="first">
                <option :value="null" >{{firstList[0]}}</option>
              </template>
            </b-form-select>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!select1" @click="select1 = null" v-if='selectList[0].visible' ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group >
          <b-input-group  style="margin-bottom: .25rem!important">
            <b-form-select v-model="select2" :options="filterList(selectList2, 'dealerid', dealer)" size="sm" v-if='selectList[1].visible' style="background-color: #c6ced4">
              <template slot="first">
                <option :value="null" >{{firstList[1]}}</option>
              </template>
            </b-form-select>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!select2" @click="select2 = null" v-if='selectList[1].visible' ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="3" v-if='isOwner||isBank'>
          <b-input-group v-if='isOwner||isBank'  style="margin-bottom: .25rem!important">
            <b-form-select v-model="select3" :options="selectList3" size="sm" v-if='selectList[2].visible' style="background-color: #c6ced4">
              <template slot="first">
                <option :value="null" >{{firstList[2]}}</option>
              </template>
            </b-form-select>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!select3" @click="select3 = null" v-if='selectList[2].visible' ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
          <b-input-group v-if='isOwner||isBank'  style="margin-bottom: .25rem!important">
            <b-form-select v-model="select4" :options="filterList(selectList4, 'supplierid', supplier)" size="sm" v-if='selectList[3].visible' style="background-color: #c6ced4">
              <template slot="first">
                <option :value="null" >{{firstList[3]}}</option>
              </template>
            </b-form-select>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!select4" @click="select4 = null" v-if='selectList[3].visible' ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col md="3" style="margin-bottom: .25rem!important">
          <section>
            <date-picker
                v-model="rangeDateTime"
                valueType="format"
                type="datetime"
                :open.sync="openDateTime"
                range
                placeholder="Выберите даты"
                :clearable="false"
                >
              <template v-slot:header="{ emit }" style="display: flex">
                <button class="mx-btn mx-btn-text" @click="setPeriod(emit,'today')">Сегодня</button>
                <button class="mx-btn mx-btn-text" @click="setPeriod(emit,'yesteday')">Вчера</button>
                <button class="mx-btn mx-btn-text" @click="setPeriod(emit,'month')">Месяц</button>
                <span style="align: right">
                  <button class="mx-btn mx-btn-text" @click="openDateTime=false">Ok</button>
                </span>
              </template>
            </date-picker>
          </section>
        </b-col>
        <b-col md="3" style="margin-bottom: .25rem!important">
          <b-input-group>
            <b-form-select v-model="selectedStatus" :options="paymentStatus" size="sm" style="background-color: #c6ced4">
            </b-form-select>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!selectedStatus" @click="selectedStatus = null"><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-table striped hover small show-empty
                table-variant="dark"
                style="color: #ccc"
                stacked="md"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @row-clicked="expandAdditionalInfo"
      >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(status)="data">
          <span v-html="data.item.status"></span>
        </template>
        <template slot="row-details" slot-scope="row" >
          <b-card name="bcard" bg-variant="dark" text-variant="secondary">
            <b-form-radio-group v-model="details"
                              :options="detailsorbill"
                              name="radioInline1"
                              size="sm">
            </b-form-radio-group>

            <ul v-if="details">
              <li v-for="(value, key) in filterItem(row.item)" :key="key" >{{ key }}: {{ value }}</li>
            </ul>
            <Invoice v-if="!details" v-bind:parameters="row.item"></Invoice>
            <b-button size="sm" @click.stop="row.toggleDetails" disabled class="mr-2" v-if="details&&(isOwner||isBank)">
              Перепровести
            </b-button>
            <b-button size="sm" @click.stop="row.toggleDetails" v-if="details&&(isOwner||isBank)">
              Отменить
            </b-button>
          </b-card>
        </template>
      </b-table>
      <infinite-loading @infinite="querytabledata" ref="infiniteLoading">
        <div slot="no-more">Больше нет платежей</div>
        <div slot="no-results"></div>
      </infinite-loading>
    </b-container>

</div>
</template>

<script>
import axios from 'axios';
import moment from 'dayjs';
import config from '../_services/config.json';
import Invoice from '@/components/Invoice';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'tablebody',
  components: {Invoice, DatePicker},
  data: () =>  ({
    items: [],
    fields: [],
    selectList:[{visible:false},{visible:false},{visible:false},{visible:false}],
    selectList1: [],
    selectList2: [],
    selectList3: [],
    selectList4: [],
    select1:null,
    select2:null,
    select3:null,
    select4:null,
    selectedStatus: null,
    paymentStatus: [
      { text: '-- Все --', value: null },
      { text: 'Не успешные', value: '-1' },
      { text: 'Accepted', value: '0' },
      { text: 'Error', value: '1' },
      { text: 'Pending', value: '2' },
      { text: 'Sending', value: '3' },
      { text: 'Processing', value: '4' }
    ],
    details: true,
    detailsorbill: [
      { text: 'Детали платежа', value: true },
      { text: 'Печать чека', value: false }
    ],
    DB: 'kiosk',
    perPage: 50,
    totalRows: 0,
    totalsum: 0,
    totalComission: 0,
    maxId: 0,
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    rangeDateTime: [moment().format('YYYY-MM-DD')+' 00:00:00',moment().format('YYYY-MM-DD')+' 23:59:59'],
    openDateTime: false,
    queryEndTime: '23:59:59',
    guid: '',
    id: '',
    requisite: '',
    billnum: '',
    dealer: null,
    terminal: null,
    supplier: null,
    service: null,
    expand: false,
    page:0,
    type: [],
    firstList: [],
    printable: false
  }),
  template: '',
  computed: {
    //currentDB () {
    //  return localStorage.getItem('currentDB')
    //},
    sortOptions () {
    // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
    isOwner () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Владелец'
      else return false
    },
    isBank () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Банк'
      else return false
    },
    isDealer () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Дилер'
      else return false
    }
  },
  watch: {
    dealer: function () {
      this.select2=null
    },
    supplier: function () {
      this.select4=null
    },
    type: function () {
      this.selectLists()
    },
    select1: function () {
      this.setqueryparam (0,this.select1)
    },
    select2: function () {
      this.setqueryparam (1,this.select2)
    },
    select3: function () {
      this.setqueryparam (2,this.select3)
    },
    select4: function () {
      this.setqueryparam (3,this.select4)
    },
  },
  methods: {
    setPeriod(emit, period) {
      let start, end;
      switch (period) {
        case 'today':
          start = new Date(moment().format('YYYY-MM-DD')+' 00:00:00');
          end = new Date(moment().format('YYYY-MM-DD')+' 23:59:59');
          break;
        case 'yesteday':
          start = new Date(moment().add(-1,'days').format('YYYY-MM-DD')+' 00:00:00');
          end = new Date(moment().add(-1,'days').format('YYYY-MM-DD')+' 23:59:59');
          break;
        case 'month':
          start = new Date(moment().date(1).format('YYYY-MM-DD')+' 00:00:00');
          end = new Date(moment().format('YYYY-MM-DD')+' 23:59:59');
          break;
      }
      emit([start,end]);
    },

    top(){
           window.scrollTo(0,0);
         },
    setqueryparam (i,id) {
      switch(this.selectList[i].idType) {
        case 'dealerId':  this.dealer=id; break;
        case 'terminalId': this.terminal=id; break;
        case 'supplierId': this.supplier=id; break;
        case 'serviceId': this.service=id;
      }
    },
    updatedata () {
        this.page=0;
        this.items=[];
        this.currentDB=localStorage.getItem('currentDB')
        this.$refs.infiniteLoading.stateChanger.reset();
    },
    filterItem (item) {
      return Object.fromEntries(Object.entries(item).filter(([key]) => !['status','_showDetails','requisite','address','dlr_address','inn','contact','title'].includes(key)))
    },
    filterList (items,property,value) {
      if (value!='')
        return items.filter(v => v[property] == value);
      else return items;
    },
    expandAdditionalInfo(row) {
      row._showDetails = !row._showDetails;
    },
    querytabledata ($state) {
      this.tablefields();
          if (this.page!=0) {
            axios.post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB',requisite: this.requisite,startDate: this.rangeDateTime[0].replace(/:|\s/g,''),
                                                          endDate: this.rangeDateTime[1].replace(/\s\d{2}:\d{2}:\d{2}$/g,'')+this.queryEndTime, billnum: this.billnum, guid: this.guid, id: this.id,
                                                          supplierId: this.supplier, dealerId: this.dealer, terminalId: this.terminal,
                                                          serviceId: this.service, partnerId: this.$store.state.authentication.user.partnerid,
                                                          partnertype: this.$store.state.authentication.user.partnertype, maxId: this.maxId,
                                                          status: this.selectedStatus, rows: this.perPage, page: this.page, type: ['Payments'],
                                                          token: this.$store.state.authentication.user.token})
            .then(response => {

              this.page +=this.perPage;
              this.items = this.items.concat(response.data);
              if (response.data.length<this.perPage) {
                $state.loaded();
                $state.complete();
              }
              else {
                $state.loaded();
              }
            })
            .catch(error => {
              if (error.response.status === 401) {
                  // auto logout if 401 response returned from api
                  this.$store.dispatch('authentication/logout');
                  this.$router.push('/')
              } else alert(error)
            });
          } else {
            axios.post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB',requisite: this.requisite,startDate: this.rangeDateTime[0].replace(/:|\s/g,''),
                                                          endDate: this.rangeDateTime[1].replace(/:|\s/g,''), billnum: this.billnum, guid: this.guid,  id: this.id,
                                                        supplierId: this.supplier, dealerId: this.dealer, terminalId: this.terminal,
                                                        serviceId: this.service, partnerId: this.$store.state.authentication.user.partnerid,
                                                        partnertype: this.$store.state.authentication.user.partnertype,
                                                        status: this.selectedStatus, rows: this.perPage, page: this.page, type: ['PaymentsTotal'],
                                                        token: this.$store.state.authentication.user.token})
          .then(total => {

              this.totalRows = total.data[0].count||0;
              this.totalsum = total.data[0].summa||0;
              this.totalComission = total.data[0].comission||0;
              this.queryEndTime=total.data[0].endtime;
              this.maxId=total.data[0].maxid;
            //console.log(total.data[0])
            if (total.data[0].count!=0) {

              axios.post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB',requisite: this.requisite,startDate: this.rangeDateTime[0].replace(/:|\s/g,''),
                                                            endDate: this.rangeDateTime[1].replace(/\s\d{2}:\d{2}:\d{2}$/g,'')+this.queryEndTime, billnum: this.billnum, guid: this.guid,  id: this.id,
                                                            supplierId: this.supplier, dealerId: this.dealer, terminalId: this.terminal,
                                                            serviceId: this.service, partnerId: this.$store.state.authentication.user.partnerid,
                                                            partnertype: this.$store.state.authentication.user.partnertype, maxId: this.maxId,
                                                            status: this.selectedStatus, rows: this.perPage, page: this.page, type: ['Payments'],
                                                            token: this.$store.state.authentication.user.token})
              .then(response => {

                this.page +=this.perPage;
                this.items = this.items.concat(response.data);
                if (response.data.length<this.perPage) {
                  $state.loaded();
                  $state.complete();
                }
                else {
                  $state.loaded();
                }
              })
              .catch(error => {
                if (error.response.status === 401) {
                    // auto logout if 401 response returned from api
                    this.$store.dispatch('authentication/logout');
                    this.$router.push('/')
                } else alert(error)
              });
            } else {
              $state.complete();
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                this.$store.dispatch('authentication/logout');
                this.$router.push('/')
            } else alert(error)
          });
        }
    },
    selectLists () {
      if (this.type) {
        axios
            .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid,
                                                          partnertype: this.$store.state.authentication.user.partnertype,
                                                          bankId: '',
                                                          type: this.type, status: false,
                                                          token: this.$store.state.authentication.user.token})
            .then(response => {
                    let i=0;
                    this.selectList1 = response.data.filter(v => v.reqtype === this.selectList.filter(v => v.label === 'select1')[0].type);
                    for ( i = 0; i < this.selectList1.length; i++) {
                      this.selectList1[i].value=this.selectList1[i][this.selectList.filter(v => v.label === 'select1')[0].value],
                      this.selectList1[i].text=this.selectList1[i][this.selectList.filter(v => v.label === 'select1')[0].text];
                    }
                    this.selectList2 = response.data.filter(v => v.reqtype === this.selectList.filter(v => v.label === 'select2')[0].type);
                    for ( i = 0; i < this.selectList2.length; i++) {
                      this.selectList2[i].value=this.selectList2[i][this.selectList.filter(v => v.label === 'select2')[0].value],
                      this.selectList2[i].text=this.selectList2[i][this.selectList.filter(v => v.label === 'select2')[0].text]
                    }
                    this.selectList3 = response.data.filter(v => v.reqtype === this.selectList.filter(v => v.label === 'select3')[0].type);
                    for ( i = 0; i < this.selectList3.length; i++) {
                      this.selectList3[i].value=this.selectList3[i][this.selectList.filter(v => v.label === 'select3')[0].value],
                      this.selectList3[i].text=this.selectList3[i][this.selectList.filter(v => v.label === 'select3')[0].text]
                    }
                    this.selectList4 = response.data.filter(v => v.reqtype === this.selectList.filter(v => v.label === 'select4')[0].type);
                    for ( i = 0; i < this.selectList4.length; i++) {
                      this.selectList4[i].value=this.selectList4[i][this.selectList.filter(v => v.label === 'select4')[0].value],
                      this.selectList4[i].text=this.selectList4[i][this.selectList.filter(v => v.label === 'select4')[0].text]
                    }
                    //console.log(this.service_list);
                  })
            .catch(error => {
              if (error.response.status === 401) {
                  // auto logout if 401 response returned from api
                  this.$store.dispatch('authentication/logout');
                  this.$router.push('/')
              } else alert(error)
            });
      }
    },
    tablefields () {
      axios
          .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB',type: ['PageConfig'], page: 'payments', partnerType: this.$store.state.authentication.user.partnertype,
                                                        partnerId: this.$store.state.authentication.user.partnerid, partnerUserRole: this.$store.state.authentication.user.role,
                                                        token: this.$store.state.authentication.user.token})
          .then(response => {
                  this.fields=JSON.parse(response.data.filter(v => v.type === 'Table')[0].value);
                  this.selectList=JSON.parse(response.data.filter(v => v.type === 'Select')[0].value)
                  this.type=[];
                  this.firstList=[];
                  this.selectList.forEach((item) => {if (item.type) this.type.push(item.type); if (item.first) this.firstList.push(item.first)})
                })
          .catch(error => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                this.$store.dispatch('authentication/logout');
                this.$router.push('/')
            } else alert(error)
          });
    },
/*    statusColor (status) {
      switch(status) {
        case 'Acepted':  return "color:green"; break;
        case 'Sending': return "color:red"; break;
        case 'Pending': return "color:red"; break;
        case 'Error': return "color:red"; break;
        case 'Cancel': return "color:green";
      }
    }
*/  },
  mounted () {
    this.tablefields();
    if (!this.isOwner&&!this.isBank) this.details=false;
  }
}
</script>
<style scoped>
.tablebody{
  background-color: #21252b;
}
.info {
  display: flex;
  padding: 10px;
  justify-content: center;

}
.paycount {
  display: block;
  width: auto;
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  background-color: #6c757d;
  color: orange;
  border-radius: 3px;
}
</style>
